.background-image {
    /* background-image: url(./assets/space-bg.jpg); */
    background-size: cover;
    background-position: top;
    min-height: 100%;
    height: 100vh;
    position: relative;
}

.registerButton {
    color: whitesmoke;
    background: linear-gradient(to right, #2A539D, #187CAB);
    margin-top: 50px;
    margin-block: 40px;
}

.registerButton:disabled {
    background: grey;
}
